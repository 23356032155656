.dropdownContainer {
  position: relative;
  width: 100%;
  max-width: 300px;
}

.dropdownHeader {
  display: inline-block;
  width: auto;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.75rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 2.5rem;
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #f0f0f0;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 0;
  margin-left: auto;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.dropdownHeader:hover {
  border-color: #c3c3c3;
  cursor: pointer;
}

.dropdownTokenHeader {
  display: flex;
  align-items: center;
  line-height: 1.1;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.85em;
  font-weight: 600;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 0;
  margin-left: auto;
  border: 1px solid #F0F0F0;
  padding: 5px 10px 5px 0px;
  border-radius: 10px;
}

.dropdownTokenHeader:hover {
  border-color: #c3c3c3;
  cursor: pointer;
}

.dropdownTokenHeader img {
  margin-left: 5px;
  margin-right: 10px;
}

.dropdownOptions {
  display: block !important;
  position: absolute;
  top: 43px;
  right: 0px;
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 5px;
  z-index: 1;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  opacity: 0;
  transform: translateY(-2.5px);
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.dropdownTokenOptions {
  top: 48px;
}

.dropdownOptions.visible {
  transform: translateY(0px);
  opacity: 1;
}

.dropdownOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
  border: 1px solid #FFFFFF;
}

.dropdownOption:hover {
  background-color: #F0F0F0;
  cursor: pointer;
}

.dropdownOption:hover img {
  border-color: #57575725;
}

.dropdownOptionSelected {
  font-weight: bold;
  background-color: #f0f0f0;
}

.dropdownOptionSelected img {
  border-color: #57575725;
}

.dropdownOption img {
  margin-left: unset;
  margin-right: 0.75rem;
}