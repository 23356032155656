.sliderComponentOuter {
  margin: 0px 20px;
  margin-bottom: 20px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.sliderComponent {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  background-color: #c3c3c3;
  border-radius: 17px;
  outline: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  cursor: pointer;
}

.sliderComponent::-webkit-slider-thumb:hover {
  background-color: #F0F0F0;
}

.sliderComponent::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border: 1px solid #c3c3c3;
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  box-shadow: none;
}

.sliderComponent::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border: 1px solid #c3c3c3;
  border-radius: 50%;
  cursor: pointer;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  box-shadow: none;
}


.sliderComponentOuter.disabled .sliderComponent {
  background-color: #d9d9d9;
  cursor: default;
}

.sliderComponentOuter.disabled .sliderComponent::-webkit-slider-thumb {
  background-color: #F0F0F0;
  border-color: #F0F0F0;
  cursor: default;
}

.sliderComponentOuter.disabled .sliderComponent::-moz-range-thumb {
  background-color: #F0F0F0;
  border-color: #F0F0F0;
  cursor: default;
}