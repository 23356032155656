.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 150ms ease 0s 1 normal both running;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}