h1, h2, h3, h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.display {
  margin: 0;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.core {
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin: auto;
  grid-gap: 20px;
  margin-top: 0;
  margin-bottom: auto;
}

.overview {
  border-radius: 15px;
  padding: 15px 0px 15px 0px;
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  min-width: 560px;
}

.overviewHeader {
  padding: 0px 0px 15px 20px;
}

.overviewHeader div {
  border-top: 1px solid #F0F0F0;
  margin-top: 15px;
  margin-left: -20px;
}

.overviewHeader h1 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 20px;
}

.overviewGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 0px;
}

.overviewGrid div {
  padding: 10px 20px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.overviewGrid h2:hover {
  color: #000000;
  cursor: pointer;
  width: max-content;
}

.overviewGrid h1 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.05ch;
  font-family: 'Space Grotesk', sans-serif;
  color: #000000;
}

.overviewGrid h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 0.5rem;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  width: max-content;
}

.overviewGrid h3 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  margin-top: 0.5rem;
}

.announcementBar {
  background-color: #FF7BED;
  text-align: center;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  display: block;
  max-width: 100%;
  color: #FFFFFF;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 8px 12px;
  text-decoration: none;
}

.announcementBar:hover {
  background-color: #ff9af1;
}

.header {
  box-sizing: border-box;
  min-width: 0px;
  width: 100%;
  margin: 8px auto 16px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 40px 0px 40px;
  min-height: 83px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #FFFFFFED;
  backdrop-filter: blur(5px);
  border-bottom: 1px solid #F0F0F0;
  margin: 0;
  transform: translateY(-100%);
}

.sticky.visible {
  transform: translateY(0%);
}

.headerLeft {
  display: flex;
  align-items: center;
}

.headerLeft img {
  cursor: pointer;
  display: block;
}

.headerLeft ul {
  box-sizing: border-box;
  margin: 0px 36px;
  min-width: 0px;
  display: grid;
  gap: 0px 24px;
  grid-auto-flow: column;
  padding: 0px;
  list-style: none;
}

.headerLeft li {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  cursor: pointer;
}

.headerLeft li:hover {
  color: #000000;
}

.headerRight {
  display: flex;
  align-items: center;
}

.headerRight h1 {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  cursor: pointer;
  margin-right: 24px;
}

.headerRight h1:hover {
  color: #000000;
}

.headerButton {
  display: inline-block;
  width: auto;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0.1rem 1.25rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.85em;
  font-weight: 600;
  border-radius: 2.5rem;
  background-color: #000000;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.headerButton:hover {
  background-color: #575757 !important;
  cursor: pointer;
}

.headerButton img {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  width: 17px;
  vertical-align: sub;
  padding: 10px;
  border-radius: 100px;
  overflow: initial;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.headerButtonWhite {
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  color: #000000;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.headerButtonWhite:hover {
  background-color: #FFFFFF !important;
  border-color: #c3c3c3;
}

.headerButtonWhite:hover img {
  filter: brightness(0);
}

.headerMenu {
  position: absolute;
  top: 80px; /* 114.5px */
  right: 40px;
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 5px;
  z-index: 1;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  opacity: 0;
  transform: translateY(-2.5px);
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.headerMenu.visible {
  opacity: 1;
  transform: translateY(0);
}

.headerMenu div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.headerMenu div:hover {
  background-color: #F0F0F0;
  cursor: pointer;
}

.headerMenu h1 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
}

.headerMenu h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 0;
  margin-left: 1rem;
}

.headerMenu .divider {
  margin: 0.25rem -5px;
  padding: 0;
  border-bottom: 1px solid #F0F0F0;
}

.headerMenu .divider:hover {
  cursor: default;
}

.headerOnMobile {
  display: none !important;
}

.notifMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px; /* 114.5px */
  min-width: 350px;
  max-width: 350px;
  min-height: 110px;
  max-height: 400px;
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  z-index: 1;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  opacity: 0;
  transform: translateY(-2.5px);
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  margin-right: 5px;
}

.notifMenu.visible {
  opacity: 1;
  transform: translateY(0);
}

.notifMenuHeader {
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notifMenuHeader h1 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  cursor: unset;
}

.notifMenuHeader a {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.notifMenuHeader a:hover {
  cursor: pointer;
  color: #000000;
}

.notifMenuView {
  margin-top: auto;
  margin-bottom: auto;
  overflow: auto;
}

.notifMenuView h2 {
  font-size: 0.85rem;
  font-weight: 600;
  text-align: center;
  color: #838383;
}

.notifMenuView::-webkit-scrollbar {
  width: 6px;
}

.notifMenuView::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 16px;
  border: 1.5px solid #FFFFFF;
}

.notifMenuView::-webkit-scrollbar-track {
  margin-bottom: 4px;
}

.notifID {
  padding: 15px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  margin: 5px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.notifID:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.notifID div {
  display: flex;
  align-items: center;
}

.notifID span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #FF7BED;
  margin-right: 0.5rem;
  border-radius: 100px;
}

.notifID h1 {
  font-size: 0.95rem;
  font-weight: 600;
  color: #000000;
}

.notifID h2 {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: left;
  margin-top: 0.5rem;
}

.notifID h3 {
  color: #575757;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: left;
  margin-top: 0.5rem;
}

@keyframes pulse {
    0% {
      opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.unreadDot {
  position: absolute;
  display: block;
  z-index: 1;
  top: 10px;
  right: 10px;
  width: 8px;
  height: 8px;
  background-color: #FF7BED;
  border-radius: 100px;
  animation: pulse 1s infinite;
}

.top {
  margin-bottom: 6rem;
  text-align: center;
}

.top h1 {
  color: #000000;
  font-size: 3.25rem;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.05ch;
}

.top span {
  color: #FF5C5C;
  background-image: linear-gradient(104deg, #FF5CD1 13%, #FF5C5C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 5px;
}

.footer {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 48px;
}

.footer div {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  margin-bottom: 0.75rem;
}

.footer span {
  width: 8px;
  height: 8px;
  background-color: #838383;
  border-radius: 100px;
  display: block;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.footer span:hover {
  opacity: 0.5;
  cursor: pointer;
}

.footer h1 {
  font-size: 0.75em;
  color: #000000;
  line-height: 1.5;
  font-weight: 600;
}

.footer h2 {
  font-size: 0.75em;
  color: #838383;
  line-height: 1.5;
  font-weight: 600;
  margin-top: 1.5rem;
}

.footer a {
  text-decoration: none;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.footer a:hover {
  color: #000000;
}

.footer ul {
  gap: 0.75rem;
  display: flex;
  justify-content: center;
  padding: 0px;
  list-style: none;
  margin: 0;
}

.footer ul:not(:first-child) {
  margin-top: 6px;
}

.footer ul:not(:last-child) {
  margin-bottom: 6px;
}

.footer li {
  font-size: 0.75em;
  color: #838383;
  line-height: 1.5;
  font-weight: 600;
  margin-top: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.footer li:hover {
  color: #000000;
  cursor: pointer;
}

.footer li a {
  text-decoration: none;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.footer li a:hover {
  color: #000000;
}

.alertDot {
  width: 8px;
  height: 8px;
  background-color: #838383;
  border-radius: 100px;
  display: block;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.alertDot:hover {
  opacity: 0.5;
  cursor: pointer;
}

.navLink {
  text-decoration: none;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.navLink:hover {
  color: #000000;
}

.navLink.active {
  color: #000000;
}

.loader {
  flex-direction: row;
  justify-content: center;
  align-items: start;
  margin: auto;
  justify-items: center;
  display: grid;
}

.loader h1 {
  margin-bottom: 0.5rem;
}

.loader h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2.5rem;
}

.loader a {
  display: inline-block;
  width: auto;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1.25rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 2.5rem;
  background-color: #000000;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.loader a:hover {
  background-color: #575757 !important;
}

.error {
  width: 30%;
}

.error img {
  width: 50px;
  height: 50px;
  background-color: #FF7BED25;
  padding: 5px;
  border-radius: 100px;
  background-color: #FF7BED25;
  margin-bottom: 1rem;
}

.loginWrapper {
  margin: auto;
}

.display::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  background: 
      linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 70%),
      url("https://cdn.quorumdao.org/background-3.png");
  box-shadow: inset 0 0 0 2000px rgba(224, 224, 224, 0.75);
  filter: brightness(125%);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  z-index: -1;
}

.loginWrapper:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  background: url("https://cdn.quorumdao.org/background-2.png");
  box-shadow: inset 0 0 0 2000px rgba(224, 224, 224, 0.75);
  filter: brightness(125%);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  z-index: -1;
}

.login {
  position: relative;
}

.login h2 {
  margin-bottom: 6rem;
}

.loginSelector {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 15px;
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  min-width: 370px;
  text-align: left;
}

.loginSelector h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  margin-bottom: 5px;
}

.loginSelector h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2.5rem;
}

.loginSelector span {
  color: #000000;
  cursor: pointer;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.loginSelector span:hover {
  color: #838383;
}

.loginSelector a {
  border-radius: 10px;
  padding: 5px;
  background-color: #00000005;
  color: #000000;
  border: 1px solid #F0F0F0;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.loginSelector a:hover {
  background-color: #000000 !important;
  color: #FFFFFF;
}

.vaultHeaderTitle div {
  display: flex !important; /* @NOTE: used to be block */
}

.vaultHeaderTitle h1 {
  margin-bottom: 5px;
}

.vaultHeader div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vaultHeader h1 {
  color: #000000;
  font-size: 1.25em;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.05ch;
}

.vaultHeader h2 {
  color: #838383;
  font-size: 0.85em;
  line-height: 1.1;
  font-weight: 600;
}

.vaultHeader a {
  color: #838383;
  font-size: 0.85em;
  line-height: 1.1;
  font-weight: 600;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}

.vaultHeader a:hover {
  color: #000000;
  cursor: pointer;
}

.vaultHeaderDivider {
  border-top: 1px solid #00000010;
  margin-top: 15px;
  margin-bottom: 30px;
}

.pageSelector {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.pageSelector button {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  margin: 0 2.5px;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.pageSelector button:hover {
  border-color: #c3c3c3;
  cursor: pointer;
}

.pageSelector button:disabled:hover {
  background-color: #575757 !important;
  cursor: pointer;
}

.pageSelector button:disabled {
  background-color: #000000;
  color: #FFFFFF;
}

.dropdownSelector {
  appearance: none;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
  outline: none;
  width: 100%;
  max-width: 300px;
  position: relative;
}

.dropdownSelector:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #333;
  pointer-events: none;
}

.pendingTxnHome {
  min-width: 560px;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.pendingTxnHome img {
  width: 30px;
  min-height: 30px;
  background-color: #F0F0F050;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 5px;
  margin-right: 1rem;
}

.pendingTxnHome h1 {
  font-size: 1rem;
  color: #000000;
  font-weight: 600;
}

.pendingTxnHome h2 {
  font-size: 0.85rem;
  color: #838383;
  font-weight: 600;
}

.activityView {
  padding: 20px;
  min-height: 500px;
  max-height: 500px;
  overflow: overlay;
  display: flex;
  flex-direction: column;
}

.activityView::-webkit-scrollbar {
  width: 6px;
}

.activityView::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 16px;
  border: 1.5px solid #FFFFFF;
}

.activityView::-webkit-scrollbar-track {
  margin-bottom: 18.5px;
}

.activityView h1 {
  font-size: 1rem;
  font-weight: 600;
}

.activityView h2 {
  color: #838383;
  font-size: 0.85rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.activityItem h2 {
  margin-top: 0.5rem;
}

.activityItem {
  padding: 15px;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  display: flex;
  align-items: flex-start;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  text-decoration: none;
  color: inherit;
}

.activityItem:not(:last-child) {
  margin-bottom: 10px;
}

.activityItem:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.activityItem:hover img {
  border-color: #57575725;
}

.activityItem img {
  width: 25px;
  min-height: 25px;
  padding: 5px;
  border: 1px solid #F0F0F0;
  background-color: #F0F0F050;
  border-radius: 10px;
  margin-right: 1rem;
}

.assetView {
  min-height: unset !important;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  padding: 0;
  margin: 20px;
}

.assetItem {
  align-items: center !important;
  margin-bottom: 0px !important;
  border: none;
  border-radius: 0px;
}

.assetItem:not(:last-child) {
  border-bottom: 1px solid #F0F0F0;
}

.assetItem:hover {
  cursor: unset !important;
  background-color: transparent !important;
}

.assetItem:hover img {
  border-color: #F0F0F0 !important;
}

.assetItem h2 {
  margin-top: 0;
}

.gridDivider {
  border-top: 1px solid #F0F0F0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.overviewGrid2 {
  padding: 0px 20px 0px 20px;
}

.overviewGrid2 div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overviewGrid2 h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.05ch;
  color: #000000;
}

.overviewGrid2 h2, .overviewGrid2 a {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
}

.overviewGrid2Divider {
  border-top: 1px solid #F0F0F0;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: -20px;
  margin-right: -20px;
}

.overviewGrid2 a {
  text-decoration: none;
  color: #000000;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.overviewGrid2 a:hover {
  color: #838383;
}

.defaultTooltip {
  font-weight: 600;
  line-height: 1.1;
  border-radius: 12px !important;
  opacity: 0 !important;
  visibility: visible;
  z-index: 1111 !important;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  border: 1px solid #F0F0F0 !important;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.defaultTooltip::before {
  z-index: -1 !important;
}

.defaultTooltip::after {
  border: 1px solid #F0F0F0 !important;
  background-color: #FFFFFF !important;
}

.defaultTooltip.show {
  visibility: visible;
  opacity: 1 !important;
}

.defaultTooltip .multi-line {
  text-align: left !important;
  font-size: 0.85rem;
  font-weight: 600;
}

.discoverInput {
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  font-family: 'Manrope', sans-serif;
  font-size: 1em;
  font-weight: 600;
  height: 48px;
  outline: 0px;
  min-width: 250px;
  padding-left: 15px;
  display: flex;
  margin-right: 1rem;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.discoverInput:focus {
  border: 1px solid #838383;
}

.discover {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10rem;
}

.discover div {
  display: flex;
  align-items: center;
}

.discover h1 {
  margin-bottom: 0.5rem;
}

.discover h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2.5rem;
}

.discover a {
  display: inline-block;
  width: auto;
  height: 48px;
  line-height: 3rem;
  padding: 0 1.25rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 10px;
  background-color: #000000;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.discover a:hover {
  background-color: #575757 !important;
  cursor: pointer;
}

.discover span {
  margin-top: 1.5rem;
  color: #000000;
  font-size: 0.75em;
  font-weight: 600;
}

@media only screen and (max-device-width: 700px) {
  
  body {
    margin: 0;
  }
  
  .header {
    justify-content: center;
    padding: 0px;
    min-height: 62.25px;
  }

  .headerLeft {
    padding: 0px 20px;
    width: 100%;
    justify-content: space-between;
  }

  .headerLeft ul {
    display: none;
  }

  .headerRight {
    padding-right: 20px;
  }

  .headerButtonWhite {
    height: fit-content;
  }

  .headerButtonWhite img {
    padding: 5px;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }

  .headerMenu {
    top: 70px; /* 123px */
    right: 20px;
  }

  .headerOnMobile {
    display: block !important;
  }

  .top {
    padding: 0px 40px;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .core {
    width: 100%;
    
  }

  .grid {
    grid-template-columns: 1fr !important;
    padding: 0px 20px;
  }

  .overview {
    min-width: fit-content !important;
    display: grid !important;
    grid-template-columns: 1fr;
  }

  .borrowDisplay {
    display: unset;
    grid-gap: 0px;
  }

  .grid2 {
    padding: 20px;
  }

  .overviewGrid {
    grid-template-columns: 1fr;
  }

  .discover {
    padding: 0px 60px;
  }

  .discover div {
    flex-direction: column;
  }

  .discover input {
    margin-bottom: 1rem;
  }

  .loader {
    padding: 0px 60px;
    text-align: center;
  }

  /** NEW **/
  .vaultHeaderPadding {
    padding: 0px 20px;
  }

  .vaultGrid {
    grid-template-columns: 1fr !important;
  }

  .manageVaultSelector {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .gridItemFirstMobile {
    order: -1;
  }

  .stabilityModuleSwapDiv {
    order: -1;
  }

  .earnDivSwap {
    margin-top: 11px;
  }

  .homeTopOverview {
    grid-template-columns: 1fr !important;
    grid-gap: 15px !important;
    padding: 0px 20px;
  }

  .homeTopOverview h2 {
    font-size: 2rem !important;
  }

  .homeCore {
    min-width: auto !important;
  }

  .homeTopOverviewButtons {
    margin-top: 2rem !important;
  }
  
  .homeTopOverviewPositionsDesc {
    margin-top: 2rem !important;
  }

  .popupOuter {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  .popupContent {
    min-width: 100% !important;
  }

  .borrowHeader {
    padding: 0px 50px;
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .borrowHeader h2 {
    margin-bottom: unset !important;
  }

  .borrowHeader br {
    display: none;
  }

  .borrowHeaderWithSelector h2 {
    margin-bottom: 2rem !important;
  }

  .pendingTxnHome {
    display: grid;
    grid-gap: 15px;
    min-width: fit-content;
    max-width: 100%;
  }

  .pendingTxnHome a {
    margin: unset;
    width: fit-content;
  }

  .pendingTxnHome h1 {
    margin-bottom: 5px;
  }

  .pendingTxnHomeMobile {
    margin-left: 20px;
    margin-right: 20px;
  }

  .borrowDivOverflowMobile {
    margin-top: -15px !important;
  }

  .notifMenu {
    top: 70px; /* 123px */
    left: 20px;
    right: 20px;
    max-width: 100%;
    margin-right: unset;
    min-width: unset;
  }

  .overviewMinimized {
    grid-gap: 15px;
  }

  .overviewMinimizedDivider {
    border-left: unset !important;
    border-bottom: 1px solid #f0f0f0;
    margin-left: -20px;
    margin-right: -20px;
  }

  .mobileDivider {
    display: block !important;
    border-bottom: 1px solid #f0f0f0;
    margin-left: -20px;
    margin-right: -20px;
  }

  .login {
    padding: 0px 36px;
  }

  .login h2 {
    margin-bottom: 3rem !important;
  }

  .loginWrapper {
    max-width: 100%;
    min-width: 100%;
  }

  .loginSelector {
    max-width: fit-content;
    min-width: 100%;
  }

  .overviewNoVaults {
    padding: 20px !important;
    grid-gap: 15px;
  }

  .overviewNoVaults img {
    margin-bottom: 0px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 10px !important;
  }

  .overviewNoVaults h1 {
    font-size: 1rem !important;
    margin-bottom: -10px !important;
  }

  .overviewNoVaults h2 {
    margin-bottom: 0px !important;
  }

  .overviewNoVaults a {
    margin-left: 0 !important;
    margin-right: auto !important;
  }

  .buyGridItem {
    margin-left: 20px;
    margin-right: 20px;
  }

  .buyGridItem .pendingTxnHome {
    margin-left: unset;
    margin-right: unset;
  }

  .poolOverviewGrid {
    grid-template-columns: 1fr !important;
  }

  .poolCompositionDiv {
    max-width: initial !important;
  }

  .borrowButtonMobile {
    display: block !important;
    height: 48px !important;
    line-height: 3rem !important;
    padding: 0.1rem 1.25rem !important;
    font-size: 0.85em !important;
  }

  .helpButton {
    right: 20px !important;
    bottom: 20px !important;
  }

  .helpButtonMenu {
    bottom: 72px !important;
    right: 20px !important;
  }
}

.mobileDivider {
  display: none;
}

/** HOME SECTION **/

.homeCore {
  margin-top: auto;
  margin-bottom: auto;
  min-width: 800px;
}

.homeTop {
  width: 60%;
  text-align: center;
  margin-top: 2.25rem;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.homeTop h1 {
  color: #000000;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 700;
  letter-spacing: -0.05ch;
  margin-bottom: 1rem;
}

.homeTop h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2.5rem;
}

.homePortfolio {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
}

.homePortfolio a {
  color: #838383;
  font-size: 0.85em;
  font-weight: 600;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  user-select: none;
  margin-right: 0;
  margin-left: auto;
}

.homePortfolio a:hover {
  color: #000000;
  cursor: pointer;
}

.smallButton {
  display: inline-block;
  width: auto;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.75rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 2.5rem;
  background-color: #000000;
  color: #FFFFFF;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 0;
  margin-left: auto;
}

.smallButton:hover {
  background-color: #575757 !important;
  cursor: pointer;
}

.imgButton {
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
  padding: 0;
  width: 32px;
  height: 32px;
  min-height: 32px;
  min-width: 32px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.imgButton img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 17px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.imgButton:hover {
  background-color: #FFFFFF !important;
  border: 1px solid #c3c3c3;
}

.imgButton:hover img {
  filter: brightness(0);
}

.homeTopOverview {
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  align-items: stretch;
  justify-content: space-between;
}

.homeTopOverview div {
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  border-radius: 15px;
  padding: 20px;
}

.homeTopOverview span {
  display: block;
  margin: 20px -20px;
  border-bottom: 1px solid #F0F0F0;
}

.homeTopOverview h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  color: #838383;
  margin-bottom: 10px;
}

.homeTopOverview h2 {
  font-size: 1.25rem;
  color: #000000;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.05ch;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 0;
}

.homeTopOverviewButtons {
  border: none !important;
  border-radius: unset !important;
  background-color: unset !important;
  box-shadow: none !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
}

.homeTopOverviewButtons button:hover {
  background-color: #F0F0F0 !important;
  border-color: #F0F0F0 !important;
}

.homeTopOverviewButtons a {
  margin-left: 0;
  color: #000000;
  background-color: #FFFFFF;
}

.homeTopOverviewButtons a:hover {
  background-color: #F0F0F0 !important;
}

.homeGrid {
  display: flex;
  grid-gap: 15px;
  flex-direction: column;
  justify-content: center;
  max-width: 1200px;
}

.overviewMinimized {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.overviewMinimized img {
  width: 74px;
  height: 37px;
  margin-right: 1rem;
  margin-left: -4px;
  object-fit: cover;
}

.overviewMinimized h1 {
  font-size: 1.15rem;
  color: #000000;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.05ch;
  font-family: 'Space Grotesk', sans-serif;
  margin-bottom: 0;
}

.overviewMinimized h2 {
  font-size: 0.85rem;
  color: #838383;
  font-weight: 600;
  margin-bottom: 5px;
}

.overviewMinimized a {
  margin: 0;
}

.overviewMinimizedDivider {
  border-left: 1px dashed #c3c3c3;
  align-self: stretch;
}

.overviewNoVaults {
  display: block;
  background: radial-gradient(250% 250% at 100% 0%, #FF9EF250 0%, #FFFFFF 50%, #FFFFFF 100%);
  padding: 40px;
}

.overviewNoVaults img {
  width: 50px;
  height: 50px;
  background: #F0F0F0;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  margin-bottom: 1rem;
}

.overviewNoVaults h1 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.overviewNoVaults h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2rem;
}

.overviewHeader2 {
  padding: 0px 15px 15px 20px;
  display: flex;
  justify-content: space-between;
}

.overviewHeader2 h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 10px;
}

.overviewHeader2 h2 {
  font-size: 0.85rem;
  color: #838383;
  font-weight: 600;
}

.overviewHeader2 a  {
  display: inline-block;
  width: auto;
  height: 2rem;
  line-height: 2rem;
  padding: 0 0.75rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.75em;
  font-weight: 600;
  border-radius: 2.5rem;
  background-color: transparent;
  color: #000000;
  border: 1px solid #F0F0F0;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

.overviewHeader2 a:hover {
  background-color: #57575710 !important;
  cursor: pointer;
}

.vaultGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.borrowHeader {
  margin-top: 2.25rem;
  margin-bottom: 6rem;
  text-align: center;
}

.borrowHeader h1 {
  line-height: 1.1;
  margin-bottom: 1rem;
}

.borrowHeader h2 {
  font-size: 1rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2.5rem;
}

.borrowNotice {
  border-radius: 15px;
  padding: 20px;
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  margin-top: 15px;
  max-width: 420px;
}

.borrowNotice h1 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  margin-bottom: 10px;
}

.borrowNotice h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
}

.borrowNoticeHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.borrowNoticeHeader h1 {
  margin-bottom: 0px;
}

.borrowDisplay {
  min-width: 450px;
  height: max-content;
}

.borrowInnerDiv {
  padding: 0px 20px 5px;
}

.borrowDivOverflow {
  margin-top: -20px;
  margin-bottom: -15px;
  padding-top: 15px;
  padding-bottom: 5px;
  overflow: overlay;
  max-height: 210px;
}

.borrowDivOverflow::-webkit-scrollbar {
  width: 6px;
}

.borrowDivOverflow::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 16px;
  border: 1.5px solid #FFFFFF;
}

.borrowInputTitle h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  color: #000000;
}

.borrowInputTitle h2 {
  font-size: 0.85rem;
  color: #838383;
  font-weight: 600;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.borrowInputTitle h2:hover {
  color: #000000;
  cursor: pointer;
}

.borrowInputTitle {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.borrowInputTitle.disabled h2:hover {
  color: #838383;
  cursor: text;
}

.borrowInput {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  outline: 0px;
  min-width: 250px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.borrowInput:hover {
  border: 1px solid #c3c3c3;
}

.borrowInput:focus-within {
  border: 1px solid #838383;
}

.borrowInput input {
  font-family: 'Manrope', sans-serif;
  font-size: 1.25em;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  padding: 15px;
  outline: 0px;
  width: 100%;
}

.borrowInput h1 {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
}

.borrowInputPrice input {
  padding: 10px 15px 0px 15px;
  box-sizing: border-box;
}

.borrowInputPrice h4 {
  font-size: 0.75rem;
  font-weight: 600;
  color: #838383;
  margin: 0px 0px 10px 15px;
}

.borrowOutputDetails {
  margin: 10px 20px;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1.75fr;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.borrowOutputDetails h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  color: #000000;
}

.borrowOutputDetails h2 {
  font-size: 0.85rem;
  color: #838383;
  font-weight: 600;
  text-align: right;
}

.borrowDivider {
  border-top: 1px solid #F0F0F0;
  margin-top: 15px;
  margin-bottom: 20px;
}

.dividerWithText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.dividerWithText div {
  border-top: 1px solid #F0F0F0;
  width: 100%;
}

.dividerWithText h1 {
  font-size: 0.85em;
  font-weight: 600;
  color: #838383;
  padding: 0 10px;
}

.repayInFullButton {
  display: flex;
  justify-content: space-between;
  margin: 5px 20px;
  width: auto;
  height: 48px;
  line-height: 3rem;
  padding: 0.1rem 1.25rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.85em;
  font-weight: 600;
  border-radius: 10px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.repayInFullButton:hover {
  border: 1px solid #c3c3c3 !important;
  cursor: pointer;
}

.borrowButton {
  display: block;
  margin: 5px 20px;
  width: auto;
  height: 48px;
  line-height: 3rem;
  padding: 0.1rem 1.25rem;
  vertical-align: middle;
  font-family: 'Manrope', sans-serif;
  font-size: 0.85em;
  font-weight: 600;
  border-radius: 100px;
  background-color: #000000;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.borrowButton:hover {
  background-color: #575757 !important;
  cursor: pointer;
}

.lightButton {
  background-color: #F0F0F0;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.lightButton:hover {
  background-color: #f0f0f085 !important;
}

.overviewGrid3 h2 {
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.overviewGrid3 h2:hover {
  color: #000000;
  cursor: pointer;
  width: max-content;
}

.manageVaultHeader h1 {
  margin-top: 0;
  margin-bottom: 0;
}

.manageVaultHeader a {
  color: #000000;
  background-color: #FFFFFF;
  margin-right: 15px;
}

.manageVaultHeader a:hover {
  background-color: #F0F0F0 !important;
}

.manageVaultHeader div.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  margin: 0;
}

.manageVaultSelector {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.manageVaultSelector div {
  padding: 5px;
  border-radius: 100px;
  background-color: #F0F0F050;
  border: 1px solid #F0F0F0;
}

.manageVaultSelector a {
  color: #838383;
  background-color: transparent;
  border: 1px solid transparent;
  height: 2rem;
  line-height: 2rem;
}

.manageVaultSelector a.active {
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
}

.manageVaultSelector a.active:hover {
  background-color: #F0F0F0 !important;
}

.manageVaultSelector a:hover {
  background-color: #F0F0F0 !important;
}

.manageVaultInnerDiv {
  padding: 0px;
  margin-top: -5px;
}

.manageVaultInnerDiv div.borrowInputTitle {
  padding: 0px 20px;
}

.manageVaultInnerDiv span.borrowInput {
  margin: 0px 20px;
}

.popupModal {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: radial-gradient(150% 150% at 50% 0,#FF9EF250 0,#fff 50%,#fff 100%);
  z-index: 800;
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  max-height: 100vh;
  overflow: auto;
}

.popupModal.visible {
  opacity: 1;
}

.popupHeader {
  padding: 20px;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupHeader a {
  text-decoration: none;
  font-size: 1.3rem;
  color: #000000;
  line-height: 1;
  font-weight: 400;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  padding-bottom: 2px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.popupHeader a:hover {
  color: #838383;
  cursor: pointer;
}

.popupHeader h1 {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
}

.popupHeader svg {
  cursor: pointer;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.popupHeader svg:hover {
  color: #838383;
}

.popupBody {
  text-align: center;
  margin: 2rem;
}

.popupScroll {
  max-height: 400px;
  overflow: overlay;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  border-bottom: 1px solid #F0F0F0;
}

.popupScroll::-webkit-scrollbar {
  width: 6px;
}

.popupScroll::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 16px;
  border: 1.5px solid #FFFFFF;
}

.popupBody img {
  height: 50px;
  width: 50px;
  padding: 5px;
  border-radius: 100px;
  background-color: #FF7BED25;
  margin-bottom: 0.75rem;
}

.popupBody h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.5rem;
}

.popupBody h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 2.5rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.popupBody h3 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
}

.popupBody a {
  text-decoration: none;
  font-size: 0.75em;
  color: #000000;
  line-height: 1;
  font-weight: 600;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.popupBody a:hover {
  color: #838383;
  cursor: pointer;
}

.popupOuter {
  margin: auto;
  display: flex;
  opacity: 0;
  transform: scale(0.95);
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.popupOuter.visible {
  opacity: 1;
  transform: scale(1);
}

.popupContent {
  min-width: 450px;
  max-width: 450px;
  border-radius: 15px;
  border: 1px solid #F0F0F0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.popupTable {
  text-align: left;
  padding: 15px;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.popupTableContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popupTable h1 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
}

.popupTable h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin-bottom: 0;
}

.apiTable {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.apiTable a {
  color: #FFFFFF;
  background-color: #838383;
  text-decoration: none;
  line-height: 2.5rem;
  margin: 0.1rem;
  font-size: 0.85rem;
  border-radius: 5px;
  width: 100%;
}

.apiTable a:hover {
  color: #FFFFFF;
}

.apiTable a.active {
  background-color: #000000;
}

.apiTableGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.apiTableGrid a {
  width: unset;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.vaultSettings {
  margin: -15px 0px;
  padding: 15px 0px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vaultSettings {
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.vaultSettings:hover {
  opacity: 0.5;
  cursor: pointer;
}

.earnDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-radius: 15px;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  padding: 25px;
  max-width: 350px;
}

.earnDiv h5 {
  font-size: 0.75rem;
  border: 1px solid #f0f0f0;
  width: fit-content;
  padding: 6px;
  border-radius: 10px;
  font-weight: 600;
  background: linear-gradient(45deg, #ff5c5c, #ff5cd1);
  color: #ffffff;
  position: absolute;
  margin-top: -41px;
}

.earnDiv h4 {
  font-size: 0.65rem;
  color: #838383;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;
}

.earnDivHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.earnDivHeader div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 250px;
}

.earnDivHeader img {
  width: 60px;
  height: 60px;
  background: #F0F0F0;
  margin-bottom: 0.5rem;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  margin-bottom: 1.25rem;
}

.earnDivHeader h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  color: #000000;
  margin-bottom: 0.85rem;
}

.earnDivHeader h2 {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  color: #838383;
  border: 1px solid #F0F0F0;
  border-radius: 100px;
  padding: 8px 10px;
  margin: 5px;
}

.earnDivContent {
  margin: 1.5rem 1rem 3rem;
  text-align: center;
}

.earnDivContent h1 {
  color: #838383;
  font-size: 0.85rem;
  font-weight: 600;
}

.earnDivCard {
  background-color: #F0F0F0;
  border-radius: 100px;
  padding: 15px;
  text-align: center;
}

.earnDivCard h2 {
  color: #000000;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
}

.earnDesc div {
  margin-bottom: 1rem;
}

.earnDesc img {
  width: 40px;
  height: 40px;
  background: #F0F0F0;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  margin-bottom: 1.25rem;
}

.earnDesc h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1;
  color: #000000;
  margin-bottom: 1rem;
}

.earnDesc h2 {
  color: #838383;
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.earnDesc h3 {
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.swapSwitcher {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-bottom: 1px dashed #f0f0f0;
  margin: 33px 20px 31px;
}

.swapSwitcher a {
  position: absolute;
  border-radius: 10px;
}

.swapSwitcher a:hover {
  background-color: #F0F0F0 !important;
  border-color: #F0F0F0 !important;
}

.swapOutput {
  display: flex;
  align-items: center;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 15px;
  margin: 0px 20px 20px;
}

.swapOutput h1 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-right: auto;
  overflow: hidden;
  margin-right: 15px;
  width: 100%;
}

.swapOutput img {
  width: 30px;
  height: 30px;
  border: 1px solid #F0F0F0;
  border-radius: 100px;
  margin-right: 1rem;
}

.swapOutputCurrency {
  display: flex;
  align-items: center;
  border-left: 1px solid #F0F0F0;
}

.swapOutputCurrency img {
  width: 25px;
  height: 25px;
  border: 1px solid #F0F0F0;
  border-radius: 100px;
  margin: 0rem 0.5rem 0rem 0.75rem;
}

.swapOutputCurrency h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.1;
  margin-right: 0px;
}

.stabilityReservesDiv {
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  padding: 10px;
  max-width: 225px;
  margin-bottom: 0px !important;
}

.stabilityReservesDiv div {
  display: flex;
  align-items: center;
  margin: 0px;
}

.stabilityReservesDiv div:not(:last-child) {
  margin-bottom: 10px;
}

.stabilityReservesDiv span {
  display: block;
  border-top: 1px solid #F0F0F0;
  margin: 15px -10px;
}

.stabilityReservesDiv h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1.1;
  margin-bottom: 0px;
  margin-right: 1rem;
}

.stabilityReservesDiv h2 {
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: 0;
}

.stabilityReservesDiv img {
  width: 25px;
  height: 25px;
  border: 1px solid #F0F0F0;
  border-radius: 100px;
  margin-bottom: 0px;
  margin-right: 0.5rem;
}

.walletView {
  min-height: 533.688px;
  max-height: 533.688px;
  overflow: overlay;
}

.walletView::-webkit-scrollbar {
  width: 6px;
}

.walletView::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  border-radius: 16px;
  border: 1.5px solid #FFFFFF;
}

.walletView::-webkit-scrollbar-track {
  margin-bottom: 18.5px;
}

.walletAssetsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.walletAssetsHeader h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
}

.walletAssetsHeader a {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  text-decoration: none;
}

.walletAssetsHeader a:hover {
  cursor: pointer;
  color: #000000;
}

.walletAssetsHeader button {
  box-shadow: none;
  width: 40px;
  height: 40px;
}

.walletAssetsHeader button img {
  width: 20px;
  height: 20px;
}

.walletAssetsHeader .buttons {
  display: flex;
  text-align: center;
}

.walletAssetsHeader .buttons h1 {
  font-size: 0.75rem;
  font-weight: 600;
  margin-top: 6px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.walletAssetsReceive {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 79px 0rem 79px 0rem;
}

.walletAssetsReceive h1 {
  line-height: 1.1;
}

.popupNotice {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  background-color: #F0F0F050;
  border-bottom: 1px solid #F0F0F0;
  padding: 15px;
  text-align: center;
}

.walletAssetsReceive a {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  text-decoration: none;
}

.walletAssetsReceive a:hover {
  cursor: pointer;
  color: #000000;
}

.walletAssetsReceive svg {
  width: 180px;
  height: 180px;
  border: 1px solid #F0F0F0;
  padding: 10px;
  border-radius: 20px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.walletAssetsReceive button {
  margin-left: unset;
  margin-right: unset;
}

.poolOverview {
  padding: 0px 20px 20px 20px;
}

.poolOverview h1 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
}

.poolOverview h2 {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
}

.poolOverviewHeader {
  display: flex;
  align-items: center;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: 1px solid #F0F0F0;
}

.poolOverviewHeader img {
  width: 74px;
  height: 37px;
  margin-right: 1rem;
  margin-left: -4px;
  object-fit: cover;
}

.poolOverviewHeader h1 {
  
}

.poolOverviewGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
}

.poolOverviewGrid div {
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 0;
}

.poolOverviewGrid h1 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -.05ch;
  font-family: "Space Grotesk",sans-serif;
  color: #000;
  margin-top: auto;
  margin-bottom: 0;
}

.poolOverviewGrid h2 {
  margin-bottom: 1rem;
}

.poolOverviewGrid h3 {
  font-size: 0.75rem;
  color: #838383;
  border: 1px solid #F0F0F0;
  padding: 6px;
  width: max-content;
  border-radius: 5px;
  margin-bottom: 0;
  margin-top: auto;
  line-height: 1;
}

.poolCompositionDiv div {
  border: none;
  padding: 0;
}

.poolCompositionDiv h1 {
  font-size: 0.85rem;
  font-weight: 600;
  font-family: unset;
  margin: unset;
}

.poolCompositionDiv h2 {
  margin-bottom: 0px;
}

.poolNotice {
  display: flex;
  flex-wrap: wrap;
}

.poolNotice h2 {
  font-size: 0.65rem !important;
  font-weight: 600;
  line-height: 1;
  color: #838383;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  padding: 6px;
  width: fit-content;
  margin-bottom: 0;
}

.poolNotice h2:not(:last-child) {
  margin-right: 5px;
}

.buyGridItem {
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  max-width: 520px;
}

.buyGridItem .vaultHeader h1 {
  font-size: 1.15rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
}

.buyGridItem .borrowDivider {
  margin: 20px -20px;
}

.buyGridItem a {
  box-shadow: unset;
  text-decoration: none;
}

.buyGridItem .pendingTxnHome {
  min-width: fit-content;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  justify-content: unset;
}

.buyGridItem .pendingTxnHome:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.buyGridItem .pendingTxnHome:hover img {
  border-color: #57575725;
}

.lpTokenImg {
  width: 74px !important;
  height: 37px !important;
  margin-left: -4px;
  object-fit: cover;
  border: none !important;
}

.guideView {
  text-align: left;
  margin: 0;
}

.guideView div {
  margin: 2rem;
}

.guideView img {
  width: 100%;
  height: 100%;
  background-color: unset;
  border-bottom: 1px solid #F0F0F0;
  border-radius: 0;
  padding: 0;
  margin: unset;
}

.guideView h1 {
  margin: unset;
  margin-bottom: 15px;
}

.guideView h2 {
  margin: 0px 0px 1.5rem 0px;
  width: 100%;
}

.guideView a {
  line-height: 2rem;
  text-decoration: none;
  color: #FFFFFF;
}

.guideView a:hover {
  color: #FFFFFF;
}

.helpButton {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 100px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  font-weight: 600;
  color: #838383;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
}

.helpButton:hover {
  cursor: pointer;
  border-color: #c3c3c3;
  color: #000000;
}

.helpButtonMenu {
  position: fixed;
  bottom: 92px; /* 80px */
  right: 40px;
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  width: 100%;
  max-width: 300px;
  height: min-content;
  max-height: 350px;
  z-index: 1;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  opacity: 0;
  transform: translateY(2.5px);
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.helpButtonMenu.visible {
  opacity: 1;
  transform: translateY(0);
}

.helpButtonMenuView a {
  align-items: center;
}

.activityItemDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.activityItemDisabled:hover {
  cursor: default;
  background-color: #FFFFFF;
}

.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.assetItemHover:hover {
  background-color: #F0F0F0 !important;
  cursor: pointer !important;
}

.assetItemHover:hover img {
  border-color: #57575725 !important;
}

.inputLink {
  font-size: 0.75rem;
  font-weight: 600;
  color: #838383 !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  text-decoration: none;
}

.inputLink:hover {
  cursor: pointer;
  color: #000000 !important;
}

.popupScrollTrackPadding::-webkit-scrollbar-track {
  margin-bottom: 18.5px;
}

.switchComponentOuter {
  min-width: 44px;
  min-height: 24px;
  position: relative;
  border-radius: 17px;
  background-color: #c3c3c3;
  transition: 0.4s;
  cursor: pointer;
}

.switchComponent {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.switchComponentOuter:hover .switchComponent {
  background-color: #F0F0F0;
}

.switchComponentOuter.active {
  background-color: #FF7BED;
}

.switchComponentOuter.active .switchComponent {
  transform: translateX(20px);
}

.switchComponentOuter.disabled {
  background-color: #d9d9d9;
  cursor: default;
}

.switchComponentOuter.disabled .switchComponent {
  background-color: #F0F0F0;
}

.liquiditySourcesList {
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  overflow: hidden;
}

.liquiditySourcesList span {
  padding: 15px;
  display: flex;
  align-items: center;
}

.liquiditySourcesList span:not(:last-child) {
  border-bottom: 1px solid #F0F0F0;
}

.liquiditySourcesList img {
  border: 1px solid #F0F0F0;
  background-color: transparent;
  width: 30px;
  height: 30px;
  padding: 0px;
  margin: 0px;
  margin-right: 1rem;
  border-radius: 10px;
}

.liquiditySourcesList span h3 {
  color: #000000;
}

.swapRouteButton {
  border: 1px solid #F0F0F0;
  border-radius: 15px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(17,17,26,0.025);
  max-width: 450px;
  margin: auto;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  opacity: 0;
  transform: translateY(-2.5px);
  width: 100%;
}

.swapRouteButton.active {
  opacity: 1;
  transform: translateY(0px);
}

.swapRouteButton a {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.swapRouteButton a:hover {
  cursor: pointer;
  color: #000000;
}

.orderRouteViewOuter:not(:last-child) {
  margin-bottom: 10px;
}

.orderRouteViewHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #F0F0F0;
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  padding: 20px;
}

.orderRouteViewHeader div {
  display: flex;
  align-items: center;
}

.orderRouteViewHeader h1 {
  font-size: 1rem;
  margin: 0;
}

.orderRouteViewHeader h2 {
  margin: 0;
  width: unset;
}

.orderRouteViewHeader img {
  width: 25px;
  height: 25px;
  margin-right: 1rem;
  margin-bottom: 0;
  border: 1px solid #F0F0F0;
  padding: 0px;
  vertical-align: middle;
}

.orderRouteView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #F0F0F0;
  background-color: #F0F0F050;
  border-radius: 0px 0px 10px 10px;
  padding: 10px;
}

.orderRouteView div {
  display: flex;
  align-items: center;
  margin: 20px;
}

.orderRouteView img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-bottom: 0;
  border: 1px solid #F0F0F0;
  padding: 0px;
  vertical-align: middle;
}

.orderRouteView h1 {
  line-height: 1.1;
  font-family: 'Manrope', sans-serif;
  font-size: 0.85rem;
  font-weight: 600;
  color: #000000;
  margin-bottom: 0;
  margin-top: 0;
}

.orderRouteView span {
  display: block;
  height: 20px;
  border-left: 1px dashed #c3c3c3;
  margin: 0px 5px;
}

.liquidatedVaultOverview {
  padding: 15px;
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
}

.liquidatedVaultOverview:not(:last-child) {
  margin-bottom: 10px;
}

.liquidatedVaultOverview:last-child {
  margin-bottom: 32px;
}

.liquidatedVaultOverview:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.liquidatedVaultOverview:hover div {
  border-color: #57575725;
}

.liquidatedVaultOverview h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.05ch;
  color: #000000;
}

.liquidatedVaultOverview h2, .liquidatedVaultOverview a {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
}

.liquidatedVaultHeader {
  text-align: left;
}

.liquidatedVaultHeader div {
  border-top: 1px solid #F0F0F0;
  margin: 15px -15px;
}

.liquidatedVaultHeader h1 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 20px;
}

.liquidatedVaultGrid div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.liquidatedVaultGrid h1 {
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.05ch;
  color: #000000;
  margin: unset;
}

.liquidatedVaultGrid h2, .liquidatedVaultGrid a {
  font-size: 0.85rem;
  font-weight: 600;
  color: #838383;
  margin: unset;
  width: unset;
}

.liquidatedVaultGridDivider {
  border-top: 1px solid #F0F0F0;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.liquidatedVaultStatus {
  background-color: #FFFFFF !important;
  border: 1px solid #F0F0F0;
  color: #000000 !important;
  -webkit-user-select: unset !important;
  -ms-user-select: unset !important;
  user-select: unset !important;
  text-decoration: unset !important;
}

.liquidatedVaultStatus:hover {
  background-color: #FFFFFF !important;
  cursor: text !important;
}

.innerBackButton {
  color: #838383 !important;
  font-size: 0.85em;
  line-height: 1.1;
  font-weight: 600;
  transition: all 150ms cubic-bezier(0.4, 0, 0, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
}

.innerBackButton:hover {
  color: #000000 !important;
  cursor: pointer;
}